<style lang="less" scoped>
  #main,
  #pie {
    width: 100%;
    height: 400px;
    margin: 10px auto;
  }
</style>

<template :is="view" transition="fade" transition-mode="out-in">
  <div class="page-wrapper">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>产品型号授权数量年度报表</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="page-container">
      <div>
        <el-form :inline="true">
          <el-form-item>
            <el-select
              v-model="year"
              placeholder="请选择年份"
              @change="getMonth"
            >
              <el-option
                v-for="(item, index) in yearList"
                :key="index"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="dvcType"
              clearable
              multiple
              @change="compareType"
              :multiple-limit="2"
              placeholder="请选择产品型号"
            >
              <el-option
                v-for="item in dvcTypeList"
                :key="item.id"
                :label="item.deviceTypeCode + item.productTypeCode"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </div>

      <el-row>
        <el-col :span="12">
          <div id="main"></div>
        </el-col>
        <el-col :span="12">
          <div id="pie"></div>
        </el-col>
      </el-row>

      <el-table :data="tableData" class="list-table">
        <el-table-column
          prop="deviceTypeCode"
          min-width="70"
          :show-overflow-tooltip="true"
          label="型号"
        >
          <template slot-scope="scope">
            {{ scope.row.deviceTypeCode + scope.row.productTypeCode }}
          </template>
        </el-table-column>

        <el-table-column
          v-for="(item, index) in monthShow"
          :key="index"
          :label="item + '月'"
        >
          <template slot-scope="scope">
            {{ scope.row.map[item] }}
          </template>
        </el-table-column>
      </el-table>

      <el-col :span="24" class="bottom-tool-container">
        <a class="hidden" ref="downloadA" :href="downloadUrl" download></a>
        <!-- <el-button type="success" @click="exportExcel" v-show="tableData.length>0">导出报表</el-button> -->
      </el-col>
    </div>
  </div>
</template>

<script>
  import {
    getProductAuthoData,
    getBrandertAuthoData,
    getAlertCountData,
    getOnlineData,
    exportProductAuthoData,
  } from "../../../services/report";
  import { searchDevicesByType } from "../../../services/device";
  import { API_ROOT, PAGE_SIZE } from "../../../config";
  import echarts from "echarts";
  import Vue from "vue";
  import { Notification } from "element-ui";
  export default {
    data() {
      return {
        downloadUrl: "",
        type: "",
        needChange: true,
        loading: false,
        legendData: [],
        seriesData: [],
        dvcTypeList: [],
        compareList: [],
        year: "",
        yearList: [],
        totalList: [],
        tableData: [],
        month: [],
        monthShow: 12,
        pager: {
          size: PAGE_SIZE,
          page: 1,
        },
        total: 0,
        AllList: [],
        singleTypeList: [],
        dvcType: [],
        chartOptions: {
          color: ["rgba(73, 169, 238, 1)", "rgba(152, 216, 125, 1)", "red"],
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              crossStyle: {
                color: "#999",
              },
            },
          },
          legend: {
            x: "center",
            itemHeight: "8",
            data: [
              {
                name: "All",
                icon: "circle",
              },
              {
                name: "型号Ⅰ",
                icon: "circle",
              },
              {
                name: "型号Ⅱ",
                icon: "circle",
              },
            ],
          },
          xAxis: {
            name: "月份",
            type: "category",
            data: [1, 2, 3, 4, 5],
            axisPointer: {
              type: "none",
            },
          },
          yAxis: [
            {
              name: "数量",
              type: "value",
              min: 0,
              axisLabel: {
                formatter: "{value} ",
              },
            },
          ],
          series: [
            {
              name: "All",
              type: "bar",
              barWidth: "10",
              data: [1, 1, 1, 1, 1],
            },
            {
              name: "型号Ⅰ",
              type: "bar",
              barWidth: "10",
              data: [1, 1, 1, 1, 1, 1, 1, 1, 1],
            },
            {
              name: "型号Ⅱ",
              type: "bar",
              barWidth: "10",
              data: [1, 1, 1, 1, 1, 1, 1, 1, 1],
            },
          ],
        },
      };
    },
    mounted() {
      this.queryDevices(""); //初始化产品型号·
      this.getYear();
      this.getMonth();
      this.getData();
      //this.initCharts();
    },
    methods: {
      // 初始化年份
      getYear() {
        const date = new Date();
        const year = date.getFullYear();
        this.year = year;
        this.yearList = [year, year - 1];
      },
      //初始化月份
      getMonth() {
        const date = new Date();
        const year = date.getFullYear();
        let month = date.getMonth() + 1;
        this.month = [];
        this.needChange = true;
        if (this.year !== year) {
          month = 12;
        }
        for (let i = 0; i < month; i++) {
          this.month.push(i + 1);
        }
      },
      // 清空表格数据
      clearTable() {
        this.tableData = [];
        console.log(this.tableData);
      },
      compareType() {
        this.compareList = [];
        this.tableData.forEach((item) => {
          if (this.dvcType.indexOf(item.deviceType) > -1) {
            let map = [];
            for (let i = 1; i <= this.month.length; i++) {
              map.push(item.map[i]);
            }
            this.compareList.push({
              name: item.deviceTypeCode + item.productTypeCode,
              map: map,
            });
          }
        });
        if (this.compareList.length === 1) {
          this.compareList.push({
            name: "",
            map: [],
          });
        }
        // console.log(this.compareList)
      },

      //产品型号授权数量报表
      async getData() {
        this.monthShow = this.month;
        let res = await getProductAuthoData(this.year);
        this.tableData = [];
        this.seriesData = [];
        this.legendData = [];
        this.totalList = [];
        if (res && res.errorCode === 0 && res.data) {
          res.data.forEach((item) => {
            if (item.deviceType) {
              this.tableData.push(item);
              this.seriesData.push({
                name: item.deviceTypeCode + item.productTypeCode,
                value: item.allCount,
                // value: 10
              });
              this.legendData.push(item.deviceTypeCode + item.productTypeCode);
            } else {
              this.totalList = [];
              for (let i = 1; i < 13; i++) {
                if (item.map[i]) {
                  this.totalList.push(item.map[i]);
                } else {
                  this.totalList.push(0);
                }
              }
            }
          });
        } else {
          Notification.warning({
            title: "提示",
            message: "暂无数据更新",
          });
        }
        this.initCharts();
        this.initPie();
      },
      /**
       * 初始化表格；
       */
      initCharts() {
        this.chartOptions.xAxis.data = this.month;
        this.chartOptions.series[0].data = this.totalList;
        for (let i = 0; i < this.compareList.length; i++) {
          this.chartOptions.series[i + 1].data = this.compareList[i].map;
          this.chartOptions.series[i + 1].name = this.compareList[i].name;
          this.chartOptions.legend.data[i + 1].name = this.compareList[i].name;
        }

        //console.log(this.totalList)
        // this.chartOptions.series[0].data = [1,2,3];
        // this.chartOptions.series[1].data = this.tableData;
        // console.log(this.tableData)
        // this.chartOptions.series[1].data = [1,2,3];
        // if(!this.isrender) {
        let myChart = echarts.init(document.getElementById("main"));
        myChart.setOption(this.chartOptions);
        // }
        // this.isrender = true;
      },
      initPie() {
        let option = {
          title: {
            text: "授权型号占比",
            x: "center",
          },
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c} ({d}%)",
          },
          legend: {
            type: "scroll",
            orient: "vertical",
            right: 10,
            top: 20,
            bottom: 20,
            data: this.legendData,
          },
          series: [
            {
              name: "姓名",
              type: "pie",
              radius: "55%",
              center: ["40%", "50%"],
              data: this.seriesData,
              itemStyle: {
                emphasis: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
        };
        let myPie = echarts.init(document.getElementById("pie"));
        myPie.setOption(option);
      },
      /**
       * 获取产品型号列表
       */
      async queryDevices(key) {
        if (key === "") {
          key = null;
        }
        this.loading = true;
        let searchParam = { key };
        const responseData = await searchDevicesByType(searchParam, {
          size: 999,
          page: 0,
        });
        if (responseData.errorCode === 0) {
          this.dvcTypeList =
            (responseData && responseData.data && responseData.data.datas) ||
            [];
          this.loading = false;
        }
      },

      /**
       * 批量导出配置
       * */
      async exportExcel() {
        const res = await exportProductAuthoData(this.year);
        if (res && res.errorCode === 0 && res.data) {
          this.downloadUrl = API_ROOT + "/" + res.data.file;
          Vue.nextTick(() => {
            this.$refs.downloadA.click();
          });
        }
      },
    },
  };
</script>
