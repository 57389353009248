/**
 * author: jorken
 * date: 2017-06-14
 * description: 报表中心
 */

import * as API from "../utils/http/api";
import { PAGE_SIZE } from "../config";

/**
 * 产品型号授权数量报表
 */
export async function getProductAuthoData(
  year,
  deviceType,
  pager = { page: 0, size: 999 }
) {
  try {
    return await API.get("staticsReport/staticsProductTypeByTime", {
      year: year,
      deviceType: deviceType,
    });
  } catch (error) {
    return error;
  }
}

/**
 * 导出产品型号授权数量报表
 */
export async function exportProductAuthoData(year) {
  try {
    return await API.get("staticsReport/export/staticsProductTypeByTime", {
      year: year,
    });
  } catch (error) {
    return error;
  }
}

/**
 * 品牌商授权产品数量报表
 */
export async function getBrandertAuthoData(
  year,
  customer,
  pager = { page: 0, size: 999 }
) {
  try {
    return await API.get("staticsReport/staticsCustomerByTime", {
      year: year,
      customer: customer,
    });
  } catch (error) {
    return error;
  }
}

/**
 * 导出品牌商授权产品数量报表
 */
export async function exportBrandertAuthoData(year) {
  try {
    return await API.get("staticsReport/export/staticsCustomerByTime", {
      year: year,
    });
  } catch (error) {
    return error;
  }
}

/**
 * 产品采购型号数量报表
 */
export async function getShopData(
  params,
  pager = { size: PAGE_SIZE, page: 1 }
) {
  try {
    return await API.get(
      "staticsReport/staticsProductTypeCustomerCountByTime",
      { ...params, ...pager }
    );
  } catch (error) {
    return error;
  }
}

/**
 * 产品销售数量报表
 */
export async function getSaleData(
  params,
  pager = { size: PAGE_SIZE, page: 1 }
) {
  try {
    return await API.get("staticsReport/staticsDeviceCustomerCountByTime", {
      ...params,
      ...pager,
    });
  } catch (error) {
    return error;
  }
}
/**
 * 故障统计报表
 */
export async function getAlertCountData(
  params,
  pager = { size: PAGE_SIZE, page: 1 }
) {
  try {
    return await API.get("staticsReport/deviceAlertRecord", {
      ...params,
      ...pager,
    });
  } catch (error) {
    return error;
  }
}

/**
 * 故障统计报表
 */
export async function getAlertCountDatas(
  params,
  pager = { size: PAGE_SIZE, page: 1 }
) {
  try {
    return await API.get("staticsReport/staticsAlertTypeByTime", {
      ...params,
      ...pager,
    });
  } catch (error) {
    return error;
  }
}

/**
 * 机器列表
 */
// export async function  (year) {
//   try {
//     return await  API.get('', {year: year});
//   } catch(error) {
//     return error;
//   }
// }

/**
 * 联网状态报表
 */
export async function getOnlineData(params) {
  try {
    return await API.get("/staticsReport/staticsOnlineCountByTimenew", params);
  } catch (error) {
    return error;
  }
}

export async function getTelemeterConfigData(
  deviceId,
  parms,
  pager = { size: PAGE_SIZE, page: 1 }
) {
  try {
    if (parms.from === 0) {
      delete parms.from;
      delete parms.to;
    }
    return await API.get("telemeterConfigData/device/" + deviceId, {
      ...parms,
      ...pager,
    });
  } catch (error) {
    return error;
  }
}

export async function exportTelemeterDataExcel(deviceId, parms) {
  try {
    if (parms.from === 0) {
      delete parms.from;
      delete parms.to;
    }
    return await API.get("telemeterConfigData/device/export/" + deviceId, {
      ...parms,
    });
  } catch (error) {
    return error;
  }
}

export async function createReport(deviceId, parms) {
  try {
    if (parms.from === 0) {
      delete parms.from;
      delete parms.to;
    }
    return await API.get("telemeterConfigData/device/generate/" + deviceId, {
      ...parms,
    });
  } catch (error) {
    return error;
  }
}

export async function getReportList(key, pager = { size: PAGE_SIZE, page: 1 }) {
  try {
    return await API.get("uploadFile", { key, ...pager });
  } catch (error) {
    return error;
  }
}

export async function downloadReport(fileName) {
  try {
    return await API.get("uploadFile/down/" + fileName);
  } catch (error) {
    return error;
  }
}

export async function deleteReport(id) {
  try {
    return await API.del("uploadFile/" + id);
  } catch (error) {
    return error;
  }
}
